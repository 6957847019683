import React, { FC } from 'react'
import cx from 'classnames'

import { IconProps } from '../Icon'
import { HtmlProps } from '../Html'

import * as SC from './styled'
import { ListIconHtmlVariant } from './types'

export type ListIconHtmlProps = {
  className?: string
  items?: {
    iconProps?: IconProps
    htmlProps?: HtmlProps
  }[]
  variant?: ListIconHtmlVariant
}

const ListIconHtml: FC<ListIconHtmlProps> = (props) => {
  const { className, items, variant } = props

  return items && items.length > 0 ? (
    <SC.ListIconHtml
      className={cx('ListIconHtml', className)}
      $variant={variant}
    >
      {items.map((item, index) => (
        <SC.Item key={`ListIconHtml-item-${index}`} $variant={variant}>
          {item.iconProps && <SC.StyledIcon {...item.iconProps} />}
          {item.htmlProps && <SC.StyledHtml {...item.htmlProps} />}
        </SC.Item>
      ))}
    </SC.ListIconHtml>
  ) : null
}

export default ListIconHtml
