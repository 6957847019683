import styled, { css } from 'styled-components'

import Html from '../Html'
import Icon from '../Icon'
import { mq, sizes } from '../../../theme'

import { ListIconHtmlVariant } from './types'

export const ListIconHtml = styled.ul<{ $variant?: ListIconHtmlVariant }>`
  list-style: none;
  display: flex;
  flex-flow: column;
  margin: 0;
  padding: 0;
  ${({ $variant }) =>
    $variant === ListIconHtmlVariant.Positive &&
    css`
      margin-bottom: 2rem;
      @media ${mq(sizes.tablet)} {
        display: flex;
        flex-direction: row;
        margin-bottom: 4rem;
        padding: 0 3rem;
      }
    `}
`
export const Item = styled.li<{ $variant?: ListIconHtmlVariant }>`
  ${({ theme }) => theme.textStyles.titleH2}
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  gap: 2rem;

  ${({ $variant }) =>
    $variant === ListIconHtmlVariant.Positive &&
    css`
      display: flex;
      align-items: center;
      ${({ theme }) => theme.textStyles.textMedium};
      color: ${({ theme }) => theme.colors.bismark};
      gap: 1rem;
      padding: 1.5rem 2rem;
      @media ${mq(sizes.tablet)} {
        flex-direction: column;
        text-align: center;
        padding: 0 1rem;
      }
    `}
`
export const StyledIcon = styled(Icon)`
  flex: 0 0 auto;
`
export const StyledHtml = styled(Html)`
  flex: 1;
  align-self: center;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-variation-settings: inherit;
  line-height: inherit;
  & > p {
    margin: 0;
  }
`
